<template>
  <FormCard
    :title="user.name ? user.name : 'Novo Usuário'"
    :status="user.status"
    @save="validate"
    @cancel="$router.push({ name: 'users' })"
  >
    <v-form
      class="px-5"
      ref="form"
    >
      <!-- Input oculto para evitar autocomplete de senha -->
      <input
        type="password"
        name="password"
        label="senha"
        class="d-none"
        autocomplete="new-password"
      />
      <section
        v-for="(section, idx) in sections"
        :key="idx"
      >
        <SectionTitle :title="section.title" />
        <component
          :is="section.component"
          :user="user"
        />
      </section>
    </v-form>

    <!-- Modal de aviso para campos obrigatórios ou erros do backend -->
    <v-dialog
      v-model="showWarningModal"
      max-width="450"
      persistent
    >
      <v-card class="pa-5">
        <v-card-title class="headline text-center">
          <v-icon
            color="warning"
            class="mr-2"
            >fas fa-exclamation-triangle</v-icon
          >
          Atenção
        </v-card-title>
        <v-card-text class="text-center">
          <div
            v-html="warningMessage"
            class="text-left"
          ></div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            @click="closeWarning"
          >
            <v-icon left>fas fa-check</v-icon> OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </FormCard>
</template>

<script>
export default {
  components: {
    FormCard: () => import('@/components/FormCard'),
    SectionTitle: () => import('@/components/SectionTitle'),
    BasicData: () => import('./form-sections/BasicData'),
    Documents: () => import('./form-sections/Documents'),
    Location: () => import('./form-sections/Location'),
    LoginData: () => import('./form-sections/LoginData'),
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    type: String,
  },
  data: () => ({
    sections: [
      { title: 'Dados Básicos', component: 'basic-data' },
      { title: 'Documentos', component: 'documents' },
      { title: 'Endereço', component: 'location' },
      { title: 'Dados de login', component: 'login-data' },
    ],
    showWarningModal: false,
    warningMessage: '',
  }),
  methods: {
    validate() {
      // Valida os campos do v-form
      let isValid = this.$refs.form.validate()
      // Array para coletar os campos obrigatórios não preenchidos
      const missingFields = []

      if (!this.user.name) {
        missingFields.push('Nome')
      }
      if (!this.user.email) {
        missingFields.push('Email')
      }
      // Agora, obrigatoriamente, é necessário preencher o Tipo de Perfil
      if (
        !this.user.type ||
        (typeof this.user.type === 'string' && this.user.type.trim() === '')
      ) {
        missingFields.push('Tipo de Perfil')
      }

      // Se houver campos faltando, monta a mensagem e exibe o modal
      if (missingFields.length > 0) {
        this.warningMessage = `
          <p>Os seguintes campos são <strong>obrigatórios</strong> e precisam ser preenchidos:</p>
          <ul>
            ${missingFields
              .map((field) => `<li><strong>${field}</strong></li>`)
              .join('')}
          </ul>
        `
        this.showWarningModal = true
        return
      }

      if (isValid) {
        this.$emit('save')
      }
    },
    closeWarning() {
      this.showWarningModal = false
    },
    // Método opcional para tratar erros vindos do backend, como email duplicado
    handleBackendError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        this.warningMessage = `<p>${error.response.data.message}</p>`
      } else {
        this.warningMessage = `<p>Ocorreu um erro ao salvar. Por favor, tente novamente.</p>`
      }
      this.showWarningModal = true
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    // Exemplo de carregamento do perfil do usuário para edição
    if (this.$cookies.get('type') == 'user' && this.type == 'edit') {
      if (this.$store.state.profile.length == 0) {
        this.getUserProfile()
      }
    }
  },
}
</script>
